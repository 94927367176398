<template>
  <b-row style="background-color: #F5F7FA; padding-bottom: 50px;" class="d-flex justify-content-center content">
    <b-col style="max-width: 1200px;">
      <b-row>

        <b-col lg="12" class="mb-4">
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body style="min-height: 150px;">
              <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600;">Activity Log</h1>
              <table class="table table-striped">
                <tbody>
                  <tr class="small" v-for="log in logs" :key="log.log_id">
                    <td width="20" v-if="log.status == 'success'" class=""><b-icon-circle-fill style="color: green;"></b-icon-circle-fill></td>
                    <td width="20" v-else class=""><b-icon-circle-fill style="color: red;"></b-icon-circle-fill></td>

                    <td>{{ log.ip_address }}</td>
                    <td class="">{{ log.description | capitalize }}</td>

                    <td v-if="log.user_id">User id: {{ log.user_id }}</td>
                    <td v-else>Public</td>

                    <td class="text-right" width="auto">{{ log.create_date }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </b-col>        

      </b-row>
    </b-col>
  </b-row>

</template>

<script>
  export default {

  name: 'log',

  components: {
    
  },

  metaInfo: {
    title: 'Activity Log'
  },

  created () {
    this.getLogs();
  },

  data() {
    return {
      logs: {},
      showLessLogs: true,
      logsVisible: 50,
    }
  },

  methods: {

    getLogs(){
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'logs', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.logs = resp.data;
        })
        // .catch(err => {
        //   alert('We have an error. Please contact support.' + err)
        //   console.log(err);
        // });
    },

  }

}

</script>

<style scoped>

.plus-icon {
  height: 40px;
  width: 40px;
  background-color: rgb(245, 247, 250);
  color: #212529;
  border-radius: 25px;
  float: right;
}

.plus-icon:hover {
  background-color: rgb(23, 92, 142);
  cursor: pointer;
  color: white;
}

.widget-item {
  text-decoration: none;
}

.widget-item:hover {
  background-color: grey;
}

.table > tbody > tr:first-child > td {
    border: none;
}

.btn-dashboard {
  color: #6c757d;
  padding: 5px;
}

.btn-dashboard:hover {
  color: white;
  border-radius: 5px;
  background-color: rgb(23, 92, 142);
  text-decoration: none;
}

.content {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 40px;
  }

}


</style>